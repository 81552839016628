.hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 4%) !important;
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06) !important; */
}

.strikethrough {
  text-decoration: line-through;
}

.card-header {
  border-radius: 0.25rem 0.25rem 0 0;
}

.popover {
  max-width: 350px;
}