.checkButton,
.createButton,
.denyButton,
.downloadButton,
.duplicateButton,
.generateButton,
.importButton,
.paidButton,
.unpaidButton,
.updateButton,
.uploadButton {
    line-height: 1.25rem !important;
    position: relative;
}

.checkButton::before,
.createButton::before,
.denyButton::before,
.downloadButton::before,
.duplicateButton::before,
.generateButton::before,
.importButton::before,
.paidButton::before,
.unpaidButton::before,
.updateButton::before,
.uploadButton::before {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 20px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.checkButton::before {
    /* check mark */
    content: "\F012C";
    font-size: 28px;
}

.createButton::before {
    /* plus */
    content: "\F0415";
    font-size: 28px;
}

.denyButton::before {
    /* close */
    content: "\F0156";
}

.downloadButton::before {
    /* arrow down underlined */
    content: "\F072E";
}

.duplicateButton::before {
    /* document copy */
    content: "\F0DC8";
}

.generateButton::before {
    /* file swap */
    content: "\F0FB4";
}

.importButton::before {
    /* import */
    content: "\F02FA";
}

.paidButton::before {
    /* cash plus */
    content: "\F1261";
}

.unpaidButton::before {
    /* cash minus */
    content: "\F1260";
}

.updateButton::before {
    /* pencil */
    content: "\F03EB";
}

.uploadButton::before {
    /* arrow up underlined */
    content: "\F005D";
}

.checkButton.showLabel::before,
.createButton.showLabel::before,
.denyButton.showLabel::before,
.downloadButton.showLabel::before,
.duplicateButton.showLabel::before,
.generateButton.showLabel::before,
.importButton.showLabel::before,
.paidButton.showLabel::before,
.unpaidButton.showLabel::before,
.updateButton.showLabel::before,
.uploadButton.showLabel::before {
    width: 44px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.08);
    line-height: 44px;
    text-align: center;
}

.checkButton.showLabel::after,
.createButton.showLabel::after,
.denyButton.showLabel::after,
.downloadButton.showLabel::after,
.duplicateButton.showLabel::after,
.generateButton.showLabel::after,
.importButton.showLabel::after,
.paidButton.showLabel::after,
.unpaidButton.showLabel::after,
.updateButton.showLabel::after,
.uploadButton.showLabel::after {
    content: attr(aria-label);
}

.btn--active-dot {
    position: relative;
}

.btn--active-dot:after {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -6px;
    right: -6px;
    background: #ffbc00;
    display: block;
    content: "";
    border: 2px #fff solid;
}
